export default {
  languageLink: 'English',
  signIn: 'Iniciar Sesión',
  name: 'Nombre',
  firstName: 'Nombre',
  lastName: 'Apellido',
  email: 'Correo electrónico',
  password: 'Contraseña',
  gender: 'Género',
  homeZipCode: 'Código Postal',
  dateOfBirth: 'Fecha de nacimiento',
  month: 'Mes',
  day: 'Día',
  year: 'Año',
  submitButton: 'Enviar',
  retryButton: 'Reintentar',
  continueButton: 'Continuar',
  yes: 'Si',
  no: 'No',
  noMatchFound: 'No se encontraron conincidencias',
  tryAgain: 'Intentar de nuevo',
  contactSupport: 'Contactar a soporte',
  'monikers.activateAccount': 'Activa tu cuenta',
  'monikers.instructions':
    'Ingrese su identificación de miembro y fecha de nacimiento. Si no es el suscriptor principal de su plan de salud, ingrese la identificación de miembro del suscriptor y su fecha de nacimiento.',
  'monikers.alternateLink': 'Intentar de otra manera',
  'monikers.ineligibleError.text':
    'No es posible confirmar la información de sus beneficios con los detalles proporcionados. Siempre puede {alternateLink} en caso de que los detalles proporcionados sean correctos según su conocimiento.',
  'monikers.ineligibleError.alternateLink': 'intentar de otra manera',
  'monikersRegister.heading': 'Completemos tu cuenta',
  'register.benefitProvider': 'Proveedor de beneficios',
  'register.welcomeHeading': 'Bienvenido',
  'register.createAccountHeading': 'Vamos a crear tu cuenta',
  'register.completeProfile': 'Completar perfil',
  'register.checkCoverage': 'Consultar cobertura',
  'register.dateOfBirth.placeholder':
    'Ingrese su fecha de nacimiento, puede agregar a los miembros de su familia más tarde.',
  'register.agreeToTerms':
    'Hacer clic en Crear cuenta significa que acepta los {termsLink} y {privacyLink} de Evernorth Virtual Care',
  'register.termsOfUse': 'Condiciones de uso',
  'register.privacyPolicy': 'Política de privacidad',
  'register.createAccount': 'Crear cuenta',
  'register.alreadyHaveAccount': '¿Ya tienes una cuenta?',
  'register.addDependent':
    '¿Quiere agregar un dependiente a una cuenta existente?',
  'register.eligibleLogin.foundYouHeader': '¡Te hemos encontrado!',
  'register.eligibleLogin.finalizeAccountHeader':
    'Estamos finalizando tu cuenta',
  'register.eligibleLogin.body':
    'Eres elegible para la cobertura de {affiliationName}.',
  'register.createAccountAffiliationHeader': 'Vamos a completar tu cuenta',
  'register.dependantsAnnouncement':
    'Si se está registrando en nombre de algún dependiente, crea una cuenta y agregalo después de iniciar sesión.',
  'register.email': '¿Cúal es tu correo electrónico?',
  'register.password': 'Crea una contraseña',
  'register.firstName': '¿Cúal es tu nombre?',
  'register.lastName': '¿Cúal es tu apellido?',
  'register.gender': '¿Cúal es tu género?',
  'register.dateOfBirth': '¿Cúal es tu fecha de nacimiento?',
  'register.phone': '¿Cúal es tu número de teléfono?',
  'register.homeZipCode': '¿Cúal es tu código postal?',
  'register.gender.female': 'Mujer',
  'register.gender.male': 'Hombre',
  'register.gender.nonBinary': 'No Binario',
  'register.memberId': 'ID de miembro del seguro',
  'register.isDependent': '¿Está asegurado como dependiente?',
  'register.skipButton': 'Saltar (Puede agregar cobertura mas adelante)',
  'month.january': 'Enero',
  'month.february': 'Febrero',
  'month.march': 'Marzo',
  'month.april': 'Abril',
  'month.may': 'Mayo',
  'month.june': 'Junio',
  'month.july': 'Julio',
  'month.august': 'Agosto',
  'month.september': 'Septiembre',
  'month.october': 'Octubre',
  'month.november': 'Noviembre',
  'month.december': 'Diciembre',
  'accountRecovery.alreadyRegistered': 'Parece que ya estás registrado',
  'accountRecovery.sendCodeInstructions':
    'Para verificar su identidad, necesitamos enviar un código de autenticación que se utilizará para iniciar sesión.',
  'accountRecovery.invalidPhoneError':
    'No tenemos su número de teléfono. Seleccione correo electrónico y vuelva a enviarlo.',
  'accountRecovery.verificationError':
    'Hubo un error al verificar su cuenta con esa información.',
  'accountRecovery.phone': 'teléfono',
  'accountRecovery.email': 'correo electrónico',
  'accountRecovery.or': 'o',
  'accountRecovery.receiveCode': '¿Cómo te gustaría recibirlo?',
  'accountRecovery.emailOption': 'Correo electrónico {email}',
  'accountRecovery.smsOption': 'Mensaje de texto a {phone}',
  'accountRecovery.noneOption': 'Ya no puedo acceder a este {accessOptions}',
  'accountRecovery.sendCodeButton': 'Enviar código',
  'accountRecovery.smsCheckAccess': 'Revisa tu teléfono',
  'accountRecovery.emailCheckAccess': 'Revisa tu correo electrónico',
  'accountRecovery.smsAccessInstructions':
    'Enviamos un mensaje de texto a {contact} con un código. Ingrese el código a continuación para iniciar sesión.',
  'accountRecovery.emailAccessInstructions':
    'Enviamos un correo electrónico a {contact} con un código. Ingrese el código a continuación para iniciar sesión.',
  'accountRecovery.authenticationCode': 'Código de autenticación',
  'accountRecovery.5digitCode': 'Código de 5 dígitos',
  'accountRecovery.verifyButton': 'Verificar',
  'accountRecovery.codeExpires':
    'El código expira 10 minutos después de solicitarlo.',
  'accountRecovery.noAuthenticationCode':
    '¿No has recibido un código de autenticación?',
  'accountRecovery.resendCodeTitle': 'Reenviar codigo',
  'accountRecovery.resendCodeBody': 'Obtenga un nuevo código de verificación',
  'accountRecovery.smsAlternateMethodTitle': 'Escríbeme',
  'accountRecovery.smsAlternateMethodBody': 'Recibe un sms con un código nuevo',
  'accountRecovery.emailAlternateMethodTitle': 'Envíeme un correo electrónico',
  'accountRecovery.emailAlternateMethodBody':
    'Reciba un correo electrónico con un nuevo código',
  'accountRecovery.contactSupportTitle': 'Contactar con soporte',
  'accountRecovery.contactSupportBody':
    'Contáctese con un especialista en servicios de salud de Evernorth Virtual Care',
  'accountRecovery.contactSupportAriaLabel':
    'Instrucciones para ponerse en contacto con soporte',
  'accountRecovery.contactSupportModalBody':
    'Conéctese con un especialista en servicios de salud de Evernorth Virtual Care en {supportNumber} para recuperar su cuenta.',
  'accountRecovery.incorrectAuthCodeError':
    'El código de autorización es incorrecto o vencido. Vuelva a enviar el código.',
  'accountRecovery.codeResent': 'Código enviado correctamente',
  'accountRecovery.codePhoneResent':
    'El código se entregará a través de una llamada telefónica.',
  'accountRecovery.resendCodeError': 'Hubo un error al reenviar el código.',
  'affiliationGroupError.notEligible': `No pudimos verificar su eligibilidad con {selectedAffiliationName}.
        Sin embargo, encontramos algunas posibles conincidencias con usted.
        Por favor, seleccione uno de los proveedores de seguro, empleadores, or grupos de beneficios mostrados abajo para continuar.`,
  'validations.alphaNumeric': 'Solo caracteres alfanuméricos',
  'validations.minLength': 'Debe tener {min} caracteres o más',
  'validations.maxLength': 'Debe tener {max} caracteres o menos',
  'validations.exactLength': 'Debe tener {target} caracteres',
  'validations.exactLengthDigits': 'Debe tener {target} dígitos',
  'validations.required': 'Requerido',
  'validations.requiredArray': 'Requerido',
  'validations.requiredLetter': 'Debe contener al menos 1 letra',
  'validations.requiredNumber': 'Debe contener al menos 1 número',
  'validations.requiredUppercase': 'Debe contener al menos 1 letra mayúscula',
  'validations.requiredLowercase': 'Debe contener al menos 1 letra minúscula',
  'validations.requiredSpecialCharacter':
    'Debe contener al menos 1 carácter especial',
  'validations.allLetters': 'Por favor introduzca solo letras',
  'validations.allNumbers': 'Por favor introduzca solo números',
  'validations.validDate': 'Fecha inválida',
  'validations.email': 'Correo electrónico inválido',
  'validations.phone': 'Debe ser un número de teléfono válido',
  'validations.before1900': 'Fecha inválida',
  'validations.yearAfter1900': 'Año inválido',
  'validations.yearInThePast': 'Debe ser un año pasado',
  'validations.beforeNow': 'Debe ser una fecha pasada',
  'validations.zipCode': 'Código postal inválido',
  'validations.validDay': 'Por favor introduzca un día entre 1 y {maxDay}',
  'validations.gender': 'Genero Requerido',
  'validations.lenghBetween': 'Debe tener entre 8 y 20 caracteres',
  'emailFieldTooltip.header': 'Tu correo electrónico',
  'emailFieldTooltip.body': 'Lo utilizaras para iniciar sesión',
  'eligibilityError.ariaLabel': 'Razón del error de elegibilidad',
  'eligibilityError.heading': 'No pudimos verificar su elegibilidad',
  'eligibilityError.notEligibleBody':
    "Nos disculpamos, pero no podemos confirmar su información de elegibilidad en este momento. Comuníquese con su administrador de beneficios para verificar su elegibilidad. También puede hacer clic en 'Reintentar' para volver a enviar su información.",
  'eligibilityError.continueWithoutCoverageBody':
    "Para confirmar la cobertura de sus beneficios, comuníquese directamente con su plan de salud. Si hace clic en 'Continuar sin cobertura', aún puede ver a un proveedor y actualizar la información del seguro más adelante.",
  'eligibilityError.continueWithoutCoverageButton': 'Continuar sin cobertura',
  'eligibilityError.callSupport': 'Llama a soporte: {phone}',
  'eligibilityError.retry': 'Reintentar',
  'error.unexpectedError': 'Hubo un error inesperado.',
  'lockedUserError.toaster':
    'La cuenta de usuario está bloqueada. Por favor, {unlockLink} para restablecer su contraseña.',
  'lockedUserError.clickHere': 'Haga clic aquí',
  'lockedUserError.modalAriaLabel': 'Instrucciones para desbloquear la cuenta',
  'lockedUserError.modalHeader': 'Restablecimiento de contraseña',
  'lockedUserError.modalAccountLocked':
    'Para ayudar a mantener su información segura, su cuenta ha sido bloqueada.',
  'lockedUserError.modalReceiveCode':
    'Le enviaremos un código para garantizar la seguridad de su cuenta. Seleccione el método en el que prefiere recibir el código.',
  'lockedUserError.modalVerificationMethod': 'método de verificación',
  'lockedUserError.modalSendLinkButton': 'Enviar enlace',
  'multipleUserAccountError.alert':
    'Encontramos multiples cuentas con esa información. Seleccione su proveedor de beneficios actual a continuación y vuelva a enviarlo.',
  'multipleUserAccountError.label':
    '¿A qué proveedor de seguros, empleador o grupo de beneficios pertenece?',
  'userZipError.alert':
    '<bold>¿Se mudó recientemente?</bold> Es posible que tengamos archivado su código postal anterior. Actualice su código postal para recuperar su cuenta.',
  'eligibilityCheckZipError.alert':
    '<bold>¿Se mudó recientemente?</bold> Es posible que tengamos su código postal anterior registrado. Actualice su código postal para crear su cuenta.',
  'passwordRecovery.headline': 'Recuperación de contraseña',
  'passwordRecovery.sms':
    'Por favor introduzca el código de autenticación que le acabamos de enviar a {connectionValue}.',
  'passwordRecovery.phone':
    'Por favor introduzca el código de autenticación que le acabamos de enviar a {connectionValue}.',
  'passwordRecovery.email':
    'Por favor introduzca el código de autenticación que le acabamos de enviar al email {connectionValue}.',
  'passwordRecovery.codeValidTime': 'El código será valido por 10 minutos.',
  'passwordRecovery.invalidCode': 'Código invalido',
  'passwordRecovery.reEnterOrResend':
    'Puede intentar reintroduciendo el código o reenviando uno nuevo.',
  'passwordRecovery.resendOrTryAnotherWay':
    'Reenvie el código o intente de otra forma',
  'passwordRecovery.resendCode': 'Reenviar código',
  'passwordRecovery.getNewCode': 'Obtener un nuevo código',
  'passwordRecovery.phoneCall': 'Llamada',
  'passwordRecovery.changePassword': 'Cambiar contraseña',
  'passwordRecovery.createStrongPassword': 'Crear un nuevo password seguro',
  'password.newPasswordLabel': 'Nueva Contraseña',
  'password.confirmNewPasswordLabel': 'Confirmar Nueva Contraseña',
  'login.signIn': 'Acceda a su cuenta',
  'login.signingYouIn': 'Accediendo...',
  'login.verifyAccountMessage.email':
    'Necesitamos asegurarnos de que esta cuenta le pertenece. Hemos enviado un código de autenticación a su correo electrónico {connectionValue}. Por favor, introduzca el código a continuación.',
  'login.verifyAccountMessage.sms':
    'Necesitamos asegurarnos de que esta cuenta le pertenece. Hemos enviado un código de autenticación al teléfono {connectionValue}. Por favor, introduzca el código a continuación.',
  'login.resendCode': 'Reenviar código',
  'login.selectDifferentAccount': 'Seleccione una cuenta diferente',
  'login.LastSignedIn': 'Último inicio de sesión',
  'login.invalidCode': 'Código inválido',
  'login.resendCodeMessage':
    'Puede intentar volviendo a introducir el código o reenviar el código',
  'login.accountRecovery.headline': 'Olvidé mi nombre de usuario',
  'login.accountRecovery.subHeadline':
    'Introduce algunos datos para que podamos obtener tu cuenta',
  'login.accountRecovery.multiple.chooseAnAccount': 'Selecciona una cuenta',
  'login.accountRecovery.failed.invalidPhoneError':
    'No tenemos registrado tu número telefonico.',
  'login.accountRecovery.failed.findAccount': '¿No encuentras tu cuenta?',
  'login.accountRecovery.failed.tooManyRequests':
    'Lo sentimos, pero has enviado demasiadas solicitudes. Por favor intentelo de nuevo más tarde',
  'login.accountRecovery.accountBelongsYou':
    'Necesitamos asegurarnos de que esta cuenta te pertenece.',
  'login.accountRecovery.enterCodeBelow':
    'Por favor introduce el código. Tu codigo será valido por 10 minutos',
  'login.accountRecovery.sms':
    'Hemos mandado un mensaje con el código de autenticación al número asociado a esta cuenta {connectionValue}.',
  'login.accountRecovery.phone':
    'Te hemos llamado para proporcionarte el código de autenticación al número asociado a esta cuenta {connectionValue}.',
  'login.accountRecovery.email':
    'Hemos enviado un correo con el código de autenticación al email asociado a esta cuenta{connectionValue}.',
  'login.accountRecovery.accountNotFound': 'No se encontro la cuenta',
  'login.accountRecovery.signIn': 'Acceda a su cuenta',
  'login.accountRecovery.accountNotFoundDescription':
    'Le pedimos disculpas. No hemos podido encontrar su cuenta con la información que nos ha proporcionado. Compruebe si hay errores tipográficos y vuelva a intentarlo.',
  'login.updatePassword.error': 'No hemos podido actualizar tu contraseña.',
  'login.label.usernameOrEmail': 'Nombre de usuario o Email',
  'login.forgotUsernameLink': 'Olvidó nombre de usuario',
  'login.forgotPasswordLink': 'Olvidó Contraseña',
  'login.orOption': 'o',
  'login.customSignInSeamlessSso': 'Iniciar sesión con ',
  'accountLocked.headline': 'Tu cuenta ha sido bloqueada',
  'accountLocked.codeValidTime':
    'Introduce el código para desbloquear tu cuenta. Tu codigo será valido por 10 minutos.',
  'accountLocked.noAuthenticationCode':
    'Reenviar código o intentar de otra forma',
};
